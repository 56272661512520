/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {Helmet} from "react-helmet"
import {messages as M} from '../views/messages';
import {envSettings} from '../views/configs/envSettings';

function SEO(props) {
    const metaUrl = props.url ? props.url : '';
    const metaImage = props.image ? props.image : envSettings.socialThumbnailImage;
    const title = props.title ? props.title : M.get('siteMetaData.home.title');
    const metaDescription = props.description ? props.description : M.get('siteMetaData.home.description');
    const sharedMetaTitle = props.sharedMetaTitle ? props.sharedMetaTitle : M.get('siteMetaData.homeSharedMeta.title');
    const sharedMetaDescription = props.sharedMetaDescription ? props.sharedMetaDescription : M.get('siteMetaData.homeSharedMeta.description');

    return (
        <Helmet>
            <meta charset="utf-8" />
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta
                name="description"
                content={metaDescription}
            />
            <link rel="apple-touch-icon" href={`${envSettings.host}logo192.png`} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={sharedMetaTitle} />
            <meta property="og:description" content={sharedMetaDescription}/>
            <meta property="og:image" content={`${envSettings.host}${metaImage}`} />
            <meta property="og:url" content={`${envSettings.host}${metaUrl}`} />

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={sharedMetaTitle} />
            <meta name="twitter:description" content={sharedMetaDescription} />
            <meta name="twitter:image" content={`${envSettings.host}${metaImage}`} />
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </Helmet>
    )
}

export default SEO
