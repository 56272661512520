let en = {
    siteMetaData: {
        home: {
            title: 'Best crowdsourced COVID-19 community maps by ZIP codes | Local data platform',
            description: 'InfoGears helps communities track coronavirus symptoms by zip code. Take the COVID19 survey daily and see the recent trends in your local area.'
        },
        homeSharedMeta: {
            title: 'Crowd-sourced COVID-19 data for your local community',
            description: 'Contribute and share to see your risk level and your local trends. Your community needs you!'
        },
        policy: {
            title: 'Privacy Policy: Best data platform for COVID-19 ZIP code maps crowdsourced for local communities',
            description: 'Privacy policy for InfoGears.org, the best data platform for COVID-19 ZIP code maps crowdsourced for local communities'
        },
        conditions: {
            title: 'Terms & Conditions: Best data platform for COVID-19 ZIP code maps crowdsourced for local communities',
            description: 'Terms & Conditions for InfoGears.org, the best data platform for COVID-19 ZIP code maps crowdsourced for local communities'
        },
        dashboard: {
            title: 'Best crowdsourced COVID-19 community maps by ZIP codes | Dashboard',
            description: 'InfoGears helps communities track coronavirus symptoms by zip code. Take the COVID19 survey daily, see the recent trends in your local area and visualize data and patterns for communities'
        },
        dashboardBurbank: {
            title: 'Best crowdsourced COVID-19 community maps by ZIP codes | Burbank - Dashboard',
            description: 'InfoGears helps communities track coronavirus symptoms by zip code. Take the COVID19 survey daily, see the recent trends in your local area and visualize data and patterns for communities like Burbank'
        },
        dashboardGlendale: {
            title: 'Best crowdsourced COVID-19 community maps by ZIP codes | Glendale - Dashboard',
            description: 'InfoGears helps communities track coronavirus symptoms by zip code. Take the COVID19 survey daily, see the recent trends in your local area and visualize data and patterns for communities like Glendale'
        },
        dashboardLA: {
            title: 'Best crowdsourced COVID-19 community maps by ZIP codes | LA County - Dashboard',
            description: 'InfoGears helps communities track coronavirus symptoms by zip code. Take the COVID19 survey daily, see the recent trends in your local area and visualize data and patterns for communities like Los Angeles County'
        },
        dashboardSharedMeta: {
            title: 'Check out Mask usage by ZIP Code and more COVID19 data!',
            description: 'See COVID19 Mental health impact, mask usage, symptoms and a lot more at InfoGrears.org!'
        }
    },
    home : {
        title1: 'Coronavirus Risk Assessment',
        title2: 'COVID-19',
        daily: 'LOCAL DAILY',
        description: {
            firstNote: 'InfoGears is a crowdsourced data site for your local community.'
        },
        new: 'NEW:',
        description1: 'With the rapid global spread of COVID-19, our risk assessment survey provides an opportunity to help individuals and communities by collecting and presenting local trends.',
        description2: 'Our risk assessment survey provides an opportunity to help individuals and communities by collecting <BOLD> <BOLD> and presenting local trends <BOLD>',
        selfReported: 'self-reported,',
        anonymousData: 'anonymous data',
        withoutCollecting: 'without collecting personal data.',
        bookmark: 'Bookmark our page',
        helpOur: 'Help your loved ones in your community with accurate information',

        title3: 'For official and up to date information visit <LINK>, <LINK> and <LINK> websites',
        resources: {
            cdc: 'CDC',
            ecde: 'ECDC',
            who: 'WHO'
        },
        conditionsAndPolicy : {
            termsConditions: 'Terms & Conditions',
            privacyPolicy: 'Privacy Policy',
            contactUs: 'Contact Us'
        },
        btnText: 'Start Assessment',
        shareText: 'HELP YOUR COMMUNITY BY SHARING OUR PAGE',
        /****** Home v2 ******/
        selfReportedData: 'Self-reported, anonymous data',
        seeZIPResults:'Your ZIP Code results on the map',
        visitDaily: 'Real-time local updates and trends',
        startAssessmentAgain: 'Start Assessment Again',
        goToMap: 'Go to Map',
        dialogTitle: 'Coronavirus Risk Assessment',
        dialogDescription1: 'The purpose of the Coronavirus Assessment is to help you assess your possible risk as well as present self-reported data in your local area. This system is using a proprietary algorithm for risk calculation that is not endorsed by any medical organization. This system is not intended for the diagnosis or treatment of disease or other conditions, including COVID-19. This system is not intended to or implied to be used for emergency needs. This system is intended only for people who are currently located in the USA.',
        dialogDescription2: 'Please review our <LINK> and <LINK>.',
        dialogSubmit: 'I AGREE',
        dialogCancel: 'I DISAGREE',
        enableCookies: 'Please enable your browser cookies in order to use this website',
        topZipCodesTitle1: 'TOP ZIP CODES',
        topZipCodesTitle2: '2 or more symptoms',
        zipCode: 'Zip Code',
        aboutUs: {
            title: 'ABOUT US',
            paragraph1: 'As we gradually reopen our communities, monitoring public health is essential for everyone\'s safety! One of the best ways to ensure this is to rely on crowdsourcing and self-reporting.  The InfoGears data platform was created for this very reason. Our goal is to help local communities visualize the prevalence of coronavirus symptoms and other aspects of life impacted by the crisis.  Our data is categorized by individual ZIP codes, catering to every neighborhood across the U.S.',
            paragraph2: 'The platform aims to provide information through easy to understand visuals. Maps and charts are leveraged to show all local community health data.',
            paragraph3: 'After completing the assessment questions, you will be able to see all the data about your neighborhood(s). All we ask is for you to come back every few days to repeat the survey and view the changes. This helps us keep the data current and relevant for you!',
            paragraph4: 'This FREE project is made possible by the NetGenix Inc. and Instigate Mobile partnership. It was launched and targeted to Glendale, Burbank, Pasadena (California) and neighboring areas. InfoGears supports all of the ZIP codes in the U.S. and has quickly gained popularity across many communities in various states.'
        },
        faceCoveringTitle: 'MASK USAGE',
        faceCoveringDetails: '% of responses',
        faceCoveringLegends: {
            never: 'Never',
            sometimes: 'Sometimes',
            always: 'Always'
        },
        moreCharts: 'More Charts'
    },
    personalInfo: {
        title: 'Personal Information',
        description: 'Include as an additional data on Demographics page, make sure they understand that anonymous is a cler option here.',
        field1: 'Full name',
        field2: 'Email address',
        field3: 'Date of Birth',
        field4: 'Phone Number',
        buttonText: 'Get Result'
    },
    RiskResult: {
        title: 'Your COVID-19 risk level is',
        description: 'This assessment is based on your responses and does not reflect your local community risk level.  It is not intended or implied to be a medical diagnosis. Please refer to your medical professional for diagnosis and medical advice; follow your local, state and federal guidelines to help prevent the spread.',
        tapTheCheckbox: 'Tap below so we can remind you to come back, retake the assessment and see how your COVID19 ZIP Code data has changed.',
        riskLevel: {
            low: 'LOW',
            high: 'HIGH',
            medium: 'MEDIUM'
        },
        buttonText: 'See the Map'
    },
    locationInfo: {
        title: 'My Area Information',
        resultsBtnText: 'Get results',
        description: 'Help your local city and neighbors by sharing your location so we can accurately show symptom prevalence on the map.',
        locationData: {
            street: 'Street',
            City: 'city',
            zipCode: 'ZIP code',
            state: 'state',
            browserLocation: 'browser location'
        }
    },
    questionnaire: {
        title: 'Questionnaire',
        nextBtnText: 'Next Page',
        resultsBtnText: 'Go to Map',
        first: {
            symptoms: 'What symptoms do you have (check all that apply)?',
            symptomsNote: 'NOTE: Having any of these symptoms does not mean you have COVID-19',
            areTestedData: {
                notTested: 'I have not been tested',
                negative: 'I have tested negative',
                positive: 'I have tested positive'
            },
            areTested: 'Have you been tested for COVID-19?',
            antibodyTest: {
                title: 'Have you taken the COVID-19 antibody test?',
                notTested: 'I have not been tested',
                positive: 'I have tested positive for COVID-19 antibodies',
                negative: 'I have tested negative for COVID-19 antibodies'
            },
            symptomsData: {
                noSymptoms: 'No Symptoms',
                persistentCough: 'Persistent Cough',
                bodyAches: 'Body Aches',
                headache: 'Headache',
                temperatureGreater: 'Fever (100.4 °F / 38 °C or greater)',
                fatigue: 'Fatigue',
                difficultBreathing: 'Difficulty breathing',
                irritatedEyes: 'Irritated / red eyes',
                diarrhea: 'Diarrhea',
                lossOfSmell: 'Loss of smell/taste',
                soreThroat: 'Sore throat',
                disorientation: 'Disorientation / Drowsiness'
            },
            mentalHealth: 'How much has the coronavirus outbreak impacted your mental health and stress level?',
            mentalHealthData: {
                noImpact: 'No impact',
                someImpact: 'Some impact',
                significantImpact: 'Significant impact'
            }
        },
        second: {
            faceCovering: 'I wear a face covering (or a mask) outside',
            faceCoveringData: {
                never: 'Never',
                sometimes: 'Sometimes',
                always: 'Always'
            },
            leftHomeFor: 'I left home for (check all that apply)',
            leftHomeForData: {
                shopping: 'Shopping',
                work: 'Work',
                exercise: 'Exercise',
                other: 'Other'
            },
            leftHomeTimes: 'Yesterday I left home',
            leftHomeTimesData: {
                didNotLeft: '0 times',
                oneTime: '1 time',
                twoTimesOrMore: '2 times or more'
            },
            exposureLevel: 'My exposure level',
            exposureLevelData: {
                doNotKnow: 'I do not know',
                haveDirectContact: 'I have direct contact with or take care of a positive COVID-19 patient'
            }
        },
        third: {
            myAge: 'What is your age?',
            myAgeData: {
                interval_18_25: '18-25',
                interval_26_35: '26-35',
                interval_36_45: '36-45',
                interval_46_55: '46-55',
                interval_56_65: '56-65',
                interval_66_75: '66-75',
                interval_75_and_more: '75+'

            },
            myGender: 'What is your gender?',
            myGenderData: {
                male: 'Male',
                female: 'Female',
                notWantToShare: 'Don\'t want to share',
                other: 'Other'
            },

            generalHealth: 'General health',
            generalHealthData: {
                noIssues: 'No health issues',
                someIssues: 'Some health issues',
                chronicIssues: 'Chronic health issues'
            },
            numberOfPeople: 'Number of people in your household (including you)',
            zipCode: 'ZIP code'
        },
        progressBar: {
            completed: 'completed',
            map: 'MAP'
        },
        questions: {
            symptoms: 'SYMPTOMS',
            activity: 'ACTIVITY',
            demographics: 'DEMOGRAPHICS'
        }
    },
    passTestDialog: {
        title: 'Please pass the assessment test to see your report',
        btnText: 'Get Started'
    },
    localMap: {
        title: 'Dashboard Information',
        title1: 'Self-reported data for ZIP Code',
        expansionPanelTitle: 'Results',
        clickForDetails: '(Tap for details)',
        searchPlaceholder: 'Search ZIP Code',
        loadingMap: 'Loading map',
        subtitles: {
            age: 'Age',
            gender: 'Gender',
            generalHealth: 'General health'
        },
        results: {
            /*results */
            results: 'RESULTS',
            positive: 'Positive',
            negative: 'Negative',
            waiting: 'Waiting',
            notTestedYet: 'Not tested yet',
            antibodyTestResults: 'Antibody Test Results',
            covidResults: 'COVID-19 Results',
            generalInfo: 'General Information',
            mapFaceCoveringChart: 'Mask Usage: % of responses',
            symptomsChart: 'Symptoms: % of responses',
            symptomsChartDetails: ' (7-day mov avg)',
            mentalHealthChart: 'Mental Health: % of responses',
            mentalHealthChartDetails: '(3-day mov avg)',
            faceCoveringChart: 'Face covering',
            /*symptoms */
            symptoms: 'SYMPTOMS',
            /*activity */
            //TODO
            activity: 'Left Home: % of responses',
            leftHomeZeroTimes: 'People who didn\'t leave home',
            leftHomeOneTime: 'People who left home 1 time',
            leftHomeTwoTimes: 'People who left home 2 or more times',
            shopping: 'Shopping',
            work: 'Work',
            exercise: 'Exercise',
            leftForOther: 'Other',
            directContact: 'People who have direct contact with or take care of a positive COVID-19 patient',
            /*demographics */
            demographics: 'DEMOGRAPHICS',
            age18_25: '18-25',
            age26_35: '26-35',
            age36_45: '36-45',
            age46_55: '46-55',
            age56_65: '56-65',
            age66_75: '66-75',
            age75Plus: '75+',
            gender: 'Gender: % of responses',
            male: 'Male',
            female: 'Female',
            notWantToShare: 'Don\'t want to share',
            genderOther: 'Other',
            generalHealth: 'GENERAL HEALTH',
            noIssues: 'No health issues',
            someIssues: 'Some health issues',
            chronicIssues: 'Chronic heath issues',
            notEnoughData: {
                title: 'Not enough data yet for {zipCode} ZIP code',
                content: 'No data has been self-reported for this locale, please share this app to help gather and present data!'
            },
            tooltip: {
                population: 'Population',
                antibodyTestPositive: 'Positive Antibody test',
                moreThanTwoSymptoms: '2 or more symptoms',
                leftHomeOneTimeAndMore: 'Left home 1+ times',
                mentalHealthImpact: {
                    someToSignificant: 'Some to Significant',
                    impact: 'mental health impact'
                },
                note: '% based on responses',
                zipCode: 'ZIP Code',
                faceCovering: 'Mask Usage (Always)',
                numberOfResponders: 'Responses'
            },
            ageChart: 'Age: % of responses',
            generalHealthChart: 'General Health: % of responses',
            generalHealthChart: 'General Health: % of responses',
            impactChart: {
                noImpact: 'No impact',
                someImpact: 'Some impact',
                significantImpact: 'Significant impact'
            },
            healthIssueChart: {
                noIssue: 'No issue',
                someIssues: 'Some issues',
                chronIcIssues: 'Chronic Issues',
            },
            gendersChart: {
                male: 'Male',
                female: 'Female',
                other: 'Other',
            },
            activityChart: {
                //TODO
                leftHomeZeroTimes: '0 times',
                leftHomeOneTime: '1 time',
                leftHomeOneTimeAndMore: '2 or more times',
            },
            barCharts: {
                symptom: {
                    PERSISTENT_COUGH: 'Persistent cough',
                    BODY_ACHE: 'Body ache',
                    HEAD_ACHE: 'Headache',
                    TEMPERATURE: 'Fever',
                    FATIGUE: 'Fatigue',
                    DIFFICULT_BREATHING: 'Difficulty breathing',
                    IRRITATED_EYES: 'Irritated eyes',
                    DIARRHEA: 'Diarrhea',
                    LOSS_OF_SMELL: 'Loss of smell',
                    SORE_THROAT: 'Sore throat',
                    DISORIENTATION: 'Disorientation',
                },
                age: {
                    INTERVAL_18_25: '18-25',
                    INTERVAL_26_35: '26-35',
                    INTERVAL_36_45: '36-45',
                    INTERVAL_46_55: '46-55',
                    INTERVAL_56_65: '56-65',
                    INTERVAL_66_75: '66-75',
                    INTERVAL_75_MORE: '75 + '
                }
            },
            notEnoughGenderData: 'Not enough data, visit again letter',
        },
        notifyDialog: {
            title: 'Send me updates for my area(s)',
            cancelText: 'CANCEL',
            submitText: 'SUBSCRIBE',
            zipCodes: 'ZIP codes'
        }
    },
    termsAndConditions: {
        title: 'Terms and Conditions ',
        agreement: {
            title: 'Agreement between User and infogears.org',
            description1: 'Welcome to infogears.org. The infogears.org website (the "Site") is comprised of various web pages operated by NetGenix Inc ("NetGenix / InfoGears"). infogears.org is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of infogears.org constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. ',
            description2: 'infogears.org is a crowd-sourced, self-reporting local data gathering and visualization Site. ',
            description3: 'to provide communities with local data based on crow-sourced, self-reported data sets'
        },
        privacy: {
            title: 'Privacy',
            description1: 'Your use of infogears.org is subject to NetGenix / InfoGears\'s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices. '
        },
        electronicCommunications: {
            title: 'Electronic Communications ',
            description1: 'Visiting infogears.org or sending emails to NetGenix / InfoGears constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing. '
        },
        yourAccount: {
            title: 'Your Account',
            description1: 'If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that NetGenix / InfoGears is not responsible for third party access to your account that results from theft or misappropriation of your account. NetGenix / InfoGears and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.'
        },
        childrenUnderThirteen: {
            title: 'Children Under Thirteen',
            description1: 'NetGenix / InfoGears does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use infogears.org only with permission of a parent or guardian.'
        },
        links: {
            title: 'Links to Third Party Sites/Third Party Services',
            description1: 'infogears.org may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of NetGenix / InfoGears and NetGenix / InfoGears is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. NetGenix / InfoGears is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by NetGenix / InfoGears of the site or any association with its operators. '
        }
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        description: 'Protecting your private information is our priority. This Statement of Privacy applies to 928 N. San Fernando Blvd. and NetGenix Inc and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to NetGenix Inc include 928 N. San Fernando Blvd., NetGenix, InfoGears, infogears.org and infogears.com. The InfoGears website is a crowd-sourced, self-reported local data site. By using the InfoGears website, you consent to the data practices described in this statement.',
        date: 'Effective as of April 11, 2020',
        personalInformation: {
            title: 'Collection of your Personal Information',
            description1: 'In order to better provide you with products and services offered on our Site, InfoGears may collect personally identifiable information, such as your:',
            firstAndLastName: 'First and Last Name',
            mailingAddress: 'Mailing Address',
            emailAddress: 'E-mail Address ',
            phoneNumber: 'Phone Number',
            employer: 'Employer',
            jobTitle: 'Job Title',
            symptoms: 'Symptoms, test results, other self-reported data',
            description2: 'If you purchase InfoGears’ products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.',
            description3: 'InfoGears may also collect anonymous demographic information, which is not unique to you, such as your:',
            age: 'Age',
            gender: 'Gender',
            description4: 'We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.'
        },
        usePersonalInformation: {
            title: 'Use of your Personal Information',
            description1: 'NetGenix / InfoGears collects and uses your personal information to operate its website(s) and deliver the services you have requested.',
            description2: 'NetGenix / InfoGears may also use your personally identifiable information to inform you of other products or services available from NetGenix and its affiliates.'
        },
        sharingInformation: {
            title: 'Sharing Information with Third Parties',
            description1: 'NetGenix does not sell, rent or lease its customer lists to third parties.',
            description2: 'NetGenix / InfoGears may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. NetGenix / InfoGears may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to NetGenix / InfoGears, and they are required to maintain the confidentiality of your information.',
            description3: 'NetGenix may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on NetGenix or the site; (b) protect and defend the rights or property of NetGenix; and/or (c) act under exigent circumstances to protect the personal safety of users of NetGenix, or the public.'
        },
        trackingUserBehavior: {
            title: 'Tracking User Behavior',
            description1: 'NetGenix / InfoGears may keep track of the websites and pages our users visit within NetGenix / InfoGears, in order to determine what NetGenix / InfoGears services are the most popular. This data is used to deliver customized content and advertising within NetGenix / InfoGears to customers whose behavior indicates that they are interested in a particular subject area.'
        },
        automaticallyCollectedInformation: {
            title: 'Automatically Collected Information',
            description1: 'Information about your computer hardware and software may be automatically collected by NetGenix. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the NetGenix website.'
        },
        useOfCookies: {
            title: 'Use of Cookies',
            description1: 'The NetGenix / InfoGears website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.',
            description2: 'One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize NetGenix / InfoGears pages, or register with NetGenix / InfoGears site or services, a cookie helps NetGenix / InfoGears to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same NetGenix / InfoGears website, the information you previously provided can be retrieved, so you can easily use the NetGenix / InfoGears features that you customized.',
            description3: 'You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the NetGenix / InfoGears services or websites you visit.'
        },
        links: {
            title: 'Links',
            description1: 'This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.'
        },
        security: {
            title: 'Security of your Personal Information',
            description1: 'NetGenix / InfoGears secures your personal information from unauthorized access, use, or disclosure. NetGenix / InfoGears uses the following methods for this purpose:',
            sslProtocol: 'SSL Protocol',
            description2: 'When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.',
            description3: 'We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.'
        },
        childrenUnderThirteen: {
            title: 'Children Under Thirteen',
            description1: 'NetGenix / InfoGears does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.'
        },
        emailCommunications: {
            title: 'E-mail Communications',
            description1: 'From time to time, NetGenix / InfoGears may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from NetGenix / InfoGears or click on a link therein.',
            description2: 'If you would like to stop receiving marketing or promotional communications via email from NetGenix / InfoGears, you may opt out of such communications by clicking on the UNSUBSCRIBE button.'
        },
        externalDataStorageSites: {
            title: 'External Data Storage Sites',
            description1: 'We may store your data on servers provided by third party hosting vendors with whom we have contracted.'
        },
        changesToThisStatement: {
            title: 'Changes to this Statement',
            description1: 'NetGenix / InfoGears reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.'
        },
        contactInformation: {
            title: 'Contact Information',
            description1: 'NetGenix welcomes your questions or comments regarding this Statement of Privacy. If you believe that NetGenix has not adhered to this Statement, please contact NetGenix at:',
            netGenixIncL: 'NetGenix Inc',
            suiteJ355: 'Suite J355',
            burbank: 'Burbank, California 91504',
            emailAddress: 'Email Address:',
            telephoneNumber: 'Telephone number:'
        }
    },
    socialShare: {
        share: 'Share',
        tweet: 'Tweet',
        notify: 'Subscribe',
        tweetTitle: 'Crowd-sourced COVID-19 data for your local community'
    },
    dashboard: {
        header: {
            title: 'InfoGears Dashboard',
            mainTitle: 'All Data',
            burbankTitle: 'Burbank, CA',
            glendaleTitle: 'Glendale, CA',
            LATitle: 'Los Angeles County, CA',
            descriptionFirst: 'One of the goals for InfoGears COVID-19 Local Data is to visualize data in a vibrant and revealing manner to allow individuals, communities and organizations make informed decisions. Below are some of the visualizations based on crowdsourced data.',
            descriptionSecondFirstPart: 'To see the data for your ',
            descriptionSecondHighlighted: 'ZIP Code',
            descriptionSecondSecondPart: ', complete the assessment. It helps with keeping the data accurate and relevant for you and your community.'
        },
        menu: {
            label: 'Choose dashboard',
            main: 'All Data',
            burbank: 'Burbank, CA',
            la: 'Los Angeles County, CA',
            glendale: 'Glendale, CA',
        },
        popup: {
            title: 'Coronavirus Risk Assessment',
            text: 'Please take the simple, 3 step assessment to see the data for your ZIP Code and help provide our communities with accurate, anonymous data about COVID19.',
            close: 'CLOSE',
            start: 'START'
        }
    },
    myburbank: {
        generate: 'Generate',
        upload: 'Change the picture in url',
        success: 'Successfully updated',
        fail: 'Failed to update'
    }
};

export {en};
