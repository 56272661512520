export const envSettings = {
    host: 'https://infogears.org/',
    showLocalData: true,
    gaSelectors: {
        startAssessment: 'google-analytics-start-assessment',
        redoAssessment: 'google-analytics-redo-assessment',
        acceptPolicy: 'google-analytics-accept-policy',
        denyPolicy: 'google-analytics-deny-policy',
        goMapFromHome: 'google-analytics-go-to-map--home',
        goMapFromResult: 'google-analytics-go-to-map--result',
        shareFacebook: 'google-analytics-share-facebook',
        shareTwitter: 'google-analytics-share-twitter',
        shareLinkedin: 'google-analytics-share-linkedin',
        shareNative: 'google-analytics-share-native',
        subscribe: 'google-analytics-subscribe',
        subscribeYes: 'google-analytics-subscribe--subscribe',
        subscribeCancel: 'google-analytics-subscribe--cancel',
        getResults: 'google-analytics-get-results',
        toggleMapDetails: 'google-analytics-toggle-map-details',
        dashboardFirstPopupStart: 'google-analytics-dashboard-first-popup-start',
        dashboardFirstPopupClose: 'google-analytics-dashboard-first-popup-close',
        dashboardSecondPopupStart: 'google-analytics-dashboard-second-popup-start',
        dashboardSecondPopupClose: 'google-analytics-dashboard-second-popup-close',
        dashboardAcceptPolicy: 'google-analytics-dashboard-accept-policy',
        dashboardDenyPolicy: 'google-analytics-dashboard-deny-policy',
        moreCharts: 'google-analytics-more-charts',
        moreChartsFromMap: 'google-analytics-more-charts-from-map',
        dashboardAcceptPolicyFirstPopup: 'google-analytics-dashboard-first-popup-accept-policy',
        dashboardDenyPolicyFirstPopup: 'google-analytics-dashboard-first-popup-deny-policy',
        dashboardAcceptPolicySecondPopup: 'google-analytics-dashboard-second-popup-accept-policy',
        dashboardDenyPolicySecondPopup: 'google-analytics-dashboard-second-popup-deny-policy',
    },
    homePageNote: 'More local dashboards added!',
    mapTooltipHoverTimeOut: 700,
    dashboardPopUpTimeOut: 40000,
    facebookDialogTimeOut: 40000,
    facebookPageID: '107621680921550',
    facebookAppID: '1678638095724206',
    facebookAppVersion: 'v3.3',
    socialThumbnailImage: 'social-thumbnail-V9.jpg',
    socialThumbnailDashboardImage: 'social-thumbnail-dashboard-v3.jpg',
};
