import {en} from './messages/en.messages';

// This is taken from Intl.getIntlMessages
let messages = {};

messages.get = function(path) {
    // todo: should be changed to be dinamic
    const current = en;
    let pathParts = path.split('.');
    let message;

    try {
        message = pathParts.reduce(function(obj, pathPart) {
            return obj[pathPart];
        }, current);
    } finally {
        if(message === undefined) {
            throw new ReferenceError(`Could not find Intl message: ${path}`);
        }
    }

    return message;
};

export {messages};
